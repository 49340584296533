import React from 'react';
import Navigation from '../navigation';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

const BreadcrumbContainer: React.FC = () => {
    return (
      <>
        <Navigation/>
        <main className='main'>
          <section>
              <div className='title'>Breadcrumb With Different Separators</div>
              <div className='section-body'>
                <Breadcrumb items = { 
                  [ 
                    {label: 'Home', url: '/alert'},
                    {label: 'Application Center', url: '/badge'},
                    {label: 'Application List', url: '/button'},
                    {label: 'An Application'}
                  ]
                }/>
                <Breadcrumb separator='/' items = { 
                  [ 
                    {label: 'Home', url: '/alert'},
                    {label: 'Application Center', url: '/badge'},
                    {label: 'Application List', url: '/button'},
                    {label: 'An Application'}
                  ]
                }/>
                <Breadcrumb separator='>' items = { 
                  [ 
                    {label: 'Home', url: '/alert'},
                    {label: 'Application Center', url: '/badge'},
                    {label: 'Application List', url: '/button'},
                    {label: 'An Application'}
                  ]
                }/>
                <Breadcrumb separator='-' items = { 
                  [ 
                    {label: 'Home', url: '/alert'},
                    {label: 'Application Center', url: '/badge'},
                    {label: 'Application List', url: '/button'},
                    {label: 'An Application'}
                  ]
                }/>
              </div>
            </section>
        </main>
      </>
    );
  };
  
export default BreadcrumbContainer;