import React from 'react';
import Button from '../../components/Button/Button';
import Navigation from '../navigation';

const ButtonContainer: React.FC = () => {
    return (
      <>
        <Navigation/>
        <main className='main'>
          <section>
            <div className='title'>Primary Buttons</div>
            <div className='section-body'>
              <Button variant='primary' size='small'>Primary</Button>
              <Button variant='primary' size='medium'>Primary</Button>
              <Button variant='primary' size='large'>Primary</Button><br/><br/>
              <Button variant='primary' size='small' disabled={true}>Primary</Button>
              <Button variant='primary' size='medium' disabled={true}>Primary</Button>
              <Button variant='primary' size='large' disabled={true}>Primary</Button>
            </div>
          </section>
          <section>
            <div className='title'>Default Buttons</div>
            <div className='section-body'>
              <Button variant='default' size='small'>Default</Button>
              <Button variant='default' size='medium'>Default</Button>
              <Button variant='default' size='large'>Default</Button><br/><br/>
              <Button variant='default' size='small' disabled={true}>Default</Button>
              <Button variant='default' size='medium' disabled={true}>Default</Button>
              <Button variant='default' size='large' disabled={true}>Default</Button>
            </div>
          </section>
          <section>
            <div className='title'>Dashed Buttons</div>
            <div className='section-body'>
              <Button variant='dashed' size='small'>Dashed</Button>
              <Button variant='dashed' size='medium'>Dashed</Button>
              <Button variant='dashed' size='large'>Dashed</Button><br/><br/>
              <Button variant='dashed' size='small' disabled={true}>Dashed</Button>
              <Button variant='dashed' size='medium' disabled={true}>Dashed</Button>
              <Button variant='dashed' size='large' disabled={true}>Dashed</Button>
            </div>
          </section>
        </main>
      </>
    );
  };
  
export default ButtonContainer;