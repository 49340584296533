import React, {CSSProperties} from 'react';
import Badge from '../../components/Badge/Badge';
import Navigation from '../navigation';

const BadgeContainer: React.FC = () => {
    const divStyle: CSSProperties = {
      position: 'relative', 
      display: 'inline-block', 
      width: '30px', 
      height: '30px',
      backgroundColor: 'lightgray',
      borderRadius: '3px',
      marginRight: '30px'
    };

    return (
      <>
        <Navigation/>
        <main className='main'>
        <section>
            <div className='title'>Badges (Default Size)</div>
            <div className='section-body'>
              <div style={divStyle}><Badge text='500' title='5'/></div>
              <div style={divStyle}><Badge text='6' title='6' status='success'/></div>
              <div style={divStyle}><Badge text='7' title='7' status='processing'/></div>
              <div style={divStyle}><Badge text='8' title='8' status='error'/></div>
              <div style={divStyle}><Badge text='9' title='9' status='warning'/></div>
            </div>
          </section>
          <section>
            <div className='title'>Badges (Small Size)</div>
            <div className='section-body'>
              <div style={divStyle}><Badge text='10' title='10' size='small'/></div>
              <div style={divStyle}><Badge text='11' title='11' size='small' status='success'/></div>
              <div style={divStyle}><Badge text='12' title='12' size='small' status='processing'/></div>
              <div style={divStyle}><Badge text='13' title='13' size='small' status='error'/></div>
              <div style={divStyle}><Badge text='14' title='14' size='small' status='warning'/></div>
            </div>
          </section>
        </main>
      </>
    );
  };
  
export default BadgeContainer;