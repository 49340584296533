import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const appElement = document.getElementById('app');

if (appElement) {
  const root = ReactDOM.createRoot(appElement);

  root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
  );
}