import React, { ChangeEvent, KeyboardEvent, FocusEvent, forwardRef, Ref } from 'react';
import classNames from 'classnames';
import './Textbox.css';

interface TextboxProps {
    value: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    disabled?: boolean;
    maxLength?: number;
    required?: boolean;
    className?: string;
    onFocus?: (e:FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e:FocusEvent<HTMLInputElement>) => void;
    onPressEnter? :() => void;
    pattern?: RegExp
}

const Textbox: React.ForwardRefRenderFunction<HTMLInputElement,TextboxProps> = (
    {
        value, 
        onChange, 
        placeholder, 
        disabled = false, 
        maxLength, 
        required, 
        className,
        onFocus,
        onBlur,
        onPressEnter,
        pattern
    },
    ref: Ref<HTMLInputElement>
) => {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        if(onChange && !disabled && (!pattern || new RegExp(pattern).test(inputValue))) {
            onChange(inputValue);
        }
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
        if(onFocus) {
            onFocus(event);
        }
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        if(onBlur) {
            onBlur(event);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && onPressEnter) {
            onPressEnter();
        }
    }

    const textboxClasses = 
    classNames("txtbox",
        {'txtbox-disabled': disabled}, 
        className);

    return (
        <input
            type="text" 
            className={textboxClasses}
            value={value} 
            onChange={handleChange}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            required={required}
            onFocus = {handleFocus}
            onBlur = {handleBlur}
            onKeyDown={handleKeyDown}
            ref = {ref}
         />
    );
};
    
export default forwardRef(Textbox);
