import React from 'react';
import Navigation from '../navigation';

const ModalContainer: React.FC = () => {
    return (
      <div className='page-layout'>
        <Navigation/>
        <main className='main'>
          <div>Modal</div>
        </main>
      </div>
    );
  };
  
export default ModalContainer;