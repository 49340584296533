import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { 
    AlertContainer, 
    AvatarContainer,
    BadgeContainer,
    BreadcrumbContainer,
    ButtonContainer,
    CheckboxContainer,
    DropdownContainer,
    ModalContainer,
    PasswordboxContainer,
    ProgressContainer,
    RadioContainer,
    RatingContainer,
    SelectContainer,
    SliderContainer,
    SwitchContainer,
    TextareaContainer,
    TextboxContainer,
    ToastContainer,
    TooltipContainer
} from '../pages';

import Home from '../pages/home-page';

const AppRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={ <Home/>} />
        <Route path="/alert" element={ <AlertContainer/>} />
        <Route path="/avatar" element={ <AvatarContainer/> } />
        <Route path="/badge" element={ <BadgeContainer/> } />
        <Route path="/breadcrumb" element={ <BreadcrumbContainer/> } />
        <Route path="/button" element={ <ButtonContainer/> } />
        <Route path="/checkbox" element={ <CheckboxContainer/> } />
        <Route path="/dropdown" element={ <DropdownContainer/> } />
        <Route path="/modal" element={ <ModalContainer/> } />
        <Route path="/passwordbox" element={ <PasswordboxContainer/> } />
        <Route path="/progress" element={ <ProgressContainer/> } />
        <Route path="/radio" element={ <RadioContainer/> } />
        <Route path="/rating" element={ <RatingContainer/> } />
        <Route path="/select" element={ <SelectContainer/> } />
        <Route path="/slider" element={ <SliderContainer/> } />
        <Route path="/switch" element={ <SwitchContainer/> } />
        <Route path="/textarea" element={ <TextareaContainer/> } />
        <Route path="/textbox" element={ <TextboxContainer/> } />
        <Route path="/toast" element={ <ToastContainer/> } />
        <Route path="/tooltip" element={ <TooltipContainer/> } />
        {/* <Route path="*" element={<AlertContainer/>} /> */}
      </Routes>
    );
  };
  
  export default AppRoutes;