import React from 'react';
import classNames from 'classnames';
import Line from './Line';
import Circle from './Circle';
import './Progress.css';

export type ProgressType = 'line' | 'circle';

export type ProgressStatus = 'normal' | 'exception' | 'active' | 'success';

export type ProgressSize = 'default' | 'small';

export interface ProgressProps {
  type?: ProgressType;
  status?: ProgressStatus;
  percent? : number;
  strokeWidth?: number;
  strokeLinecap?: 'butt' | 'square' | 'round';
  strokeColor?: string;
  trailColor?: string;
  width?: number;
  height?: number;
  size?: number | ProgressSize;
  className?: string;
  onProgress?: (percentage: number) => void;
  onComplete?: () => void;
}

const Progress = React.forwardRef<HTMLDivElement, ProgressProps>((props, ref) => {
  
  const {
    type, 
    status = 'normal',
    size = 'default',
    className,
    onComplete,
    onProgress,
    percent
  } = props;

  const classString = classNames('progress',
   {'progress-default': size === 'default'},
   {'progress-small': size === 'small'},
   {'progress-line': type === 'line'},
   {'progress-circle': type === 'circle'},
   {'progress-status-normal': status === 'normal'},
   {'progress-status-exception': status === 'exception'},
   {'progress-status-success': status === 'success'},
   {'progress-status-active': status === 'active'},
  className);
  
  let progress: React.ReactNode;
  
  if (type === 'line'){
    progress = <Line {...props}/>
  }
  else if(type === 'circle'){
    progress = <Circle {...props}/>
  }

  onProgress?.(percent ?? 0);

  if (percent === 100){
    onComplete?.();
  }

  return (
    <div ref={ref} className={classString} role='progressbar' title='progress'>
      {progress}
    </div>
  );
});

export default Progress;