import React from 'react';
import type {ProgressProps} from './Progress';
import { validProgress } from './utils';

interface LineProps extends ProgressProps {
    children?: React.ReactNode;
    strokeColor?: string;
    height?: number;
}

const Line: React.FC<LineProps> = (props) => {
    const {
        children,
        percent,
        height = 8,
        strokeLinecap = 'round'
    } = props;

    const outerStyle: React.CSSProperties = {
        width: `100%`,
        height: `${height}px`
      };

    const innerStyle: React.CSSProperties = {
        ...((strokeLinecap === 'butt' || strokeLinecap === 'square') && {borderRadius: 0})
      };

    const percentStyle: React.CSSProperties = {
        width: `${validProgress(percent)}%`,
        height: `${height}px`,
        ...((strokeLinecap === 'butt' || strokeLinecap === 'square') && {borderRadius: 0})
      };

    return (
        <>
            <div className='progress-outer' style = {outerStyle}>
                <div className='progress-inner' style={innerStyle}>
                    <div className='progress-bg' style={percentStyle}></div>
                </div>
            </div>
            {children}
        </>
    )
};

export default Line;
