import React, {useState} from 'react';
import Navigation from '../navigation';
import Textarea from '../../components/Textarea/Textarea';

const TextareaContainer: React.FC = () => {
  const [text, setText] = useState('');

  const handleChange = (value: string) => {
    setText(value);
  }

    return (
      <>
        <Navigation/>
        <main className='main'>
          <section>
            <div className='title'>Textareas</div>
            <div className='section-body'>
              <Textarea value={text} onChange={handleChange}/>
              <Textarea value={text} onChange={handleChange} rows={8}/>
              <Textarea value={text} onChange={handleChange} disabled={true}/>
              <Textarea value={text} placeholder='Enter a short bio' onChange={handleChange}/>
              <Textarea value={text} onChange={handleChange} maxLength={200}/>
              <Textarea value={text} onChange={handleChange} maxLength={200} showCount={true}/>
            </div>
          </section>
        </main>
      </>
    );
  };
  
export default TextareaContainer;