import React from 'react';
import type {ProgressProps} from './Progress';

interface CircleProps extends ProgressProps {

}

const Circle : React.FC<CircleProps> = (props) => {
    const { 
        percent = 0, 
        width = 80, 
        height = 80,
        strokeLinecap = 'round',
        strokeWidth = 6,
        trailColor,
    } = props;

    if (width !== height) {
        throw new Error(`Width and Height needs to have the same size. [Width:${width} - Height ${height}]`);
    }

    const progressCircleInnerStyle: React.CSSProperties = {
        width: `${width}px`,
        height: `${height}px`,
    }

    const circleTrailStyle: React.CSSProperties = {
        strokeLinecap: strokeLinecap,
        stroke: trailColor,
        ...(width < 55 ? {strokeWidth: '20px'} : {strokeWidth: `${strokeWidth}px`})
    }

    const circlePathStyle: React.CSSProperties = {
        strokeLinecap: strokeLinecap,
        strokeDashoffset: 295.31 - ((percent * 295.31) / 100),
        ...(width < 55 ? {strokeWidth: '20px'} : {strokeWidth: `${strokeWidth}px`})
    }

    const circlePathStyle2: React.CSSProperties = {
        strokeLinecap: strokeLinecap,
        ...(width < 55 ? {strokeWidth: '20px'} : {strokeWidth: `${strokeWidth}px`})
    }

    const title = `${percent}%`;

    return(
        <div className="progress-circle-inner" style={progressCircleInnerStyle}>
            <svg className="progress-circle" viewBox="-50 -50 100 100" role="presentation">
                <circle className="progress-circle-trail" r="47" cx="0" cy="0" style = {circleTrailStyle}></circle>
                <circle className="progress-circle-path" r="47" cx="0" cy="0" style = {circlePathStyle}></circle>
                <circle className="progress-circle-path-2" r="47" cx="0" cy="0" style = {circlePathStyle2}></circle>
            </svg>
            {width > 55 &&
                <span className="progress-text" title={title}>{title}</span>
            }
        </div>
    )
};

export default Circle;


