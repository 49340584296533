import React, {useState, FocusEvent} from 'react';
import Navigation from '../navigation';
import Textbox from '../../components/Textbox/Textbox';
import patterns from '../../utils/regexPatterns';

const TextboxContainer: React.FC = () => {
    const [text, setText] = useState('');

    const handleChange = (value: string) => {
      setText(value);
    }

    const handleFocus = (e:FocusEvent<HTMLInputElement>) => {
      console.log("Triggered onFocus event")
    }

    const handleBlur = (e:FocusEvent<HTMLInputElement>) => {
      console.log("Triggered onBlur event")
    }

    const handlePressEnter = () => {
      console.log("Pressed enter key")
    }

    return (
      <>
        <Navigation/>
        <main className='main'>
          <Textbox value={text} onChange={handleChange}/>
          <Textbox value={text} onChange={handleChange} placeholder='Enter your first name'/>
          <Textbox value={text} className='text-cl text-cl2' onChange={handleChange} placeholder='Enter your last name'/>
          <Textbox value={text} onChange={handleChange} maxLength={10}/>
          <Textbox value={text} onChange={handleChange} onFocus = {handleFocus} onBlur = {handleBlur}/>
          <Textbox value={text} onChange={handleChange} onPressEnter={handlePressEnter}/>
          <Textbox value={text} onChange={handleChange} disabled={true}/>
          <Textbox value={text} onChange={handleChange} required={true}/>
          <Textbox value={text} onChange={handleChange} pattern= {patterns.numbers} placeholder='Enter only numbers'/>
        </main>
      </>
    );
  };
  
export default TextboxContainer;