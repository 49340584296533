import React from 'react';
import Radio from '../../components/Radio/Radio';
import RadioGroup from '../../components/RadioGroup/RadioGroup';
import Navigation from '../navigation';

const teams = [
  {
    label: "Olympiakos", value: "olympiakos"
  },
  {
    label: "Panathinaikos", value: "panathinaikos"
  },
  {
    label: "AEK", value: "aek"
  },
  {
    label: "Paok", value: "paok"
  },
  {
    label: "Aris", value: "aris"
  }
]

const RadioContainer: React.FC = () => {
    return (
      <>
        <Navigation/>
        <main className='main'>
          <section>
            <div className='title'>Small Radios</div>
            <div className='section-body'>
              <Radio size='small' label='Radio' value='1'/>
              <Radio size='small' label='Radio' value='2' selectedValue='2'/>
              <Radio size='small' label='Disabled' value='3' disabled={true}/>
              <Radio size='small' label='Disabled' value='4' selectedValue='4' disabled={true}/>
            </div>
          </section>
          <section>
            <div className='title'>Default Radios</div>
            <div className='section-body'>
              <Radio label='Radio' value='1'/>
              <Radio label='Radio' value='2' selectedValue='2'/>
              <Radio label='Disabled' value='3' disabled={true}/>
              <Radio label='Disabled' value='4' selectedValue='4' disabled={true}/>
            </div>
          </section>
          <section>
            <div className='title'>Large Radios</div>
            <div className='section-body'>
              <Radio size='large' label='Radio' value='1'/>
              <Radio size='large' label='Radio' value='2' selectedValue='2'/>
              <Radio size='large' label='Disabled' value='3' disabled={true}/>
              <Radio size='large' label='Disabled' value='4' selectedValue='4' disabled={true}/>
            </div>
          </section>
          <section>
            <div className='title'>Radio Group</div>
            <div className='section-body'>
              <RadioGroup name='teams' options={teams}/>
            </div>
          </section>
        </main>
      </>
    );
  };
  
export default RadioContainer;