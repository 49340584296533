import React, { useState } from 'react';
import classNames from 'classnames';
import './Textarea.css';

interface TextareaProps {
  value: string;
  maxLength?: number;
  onChange: (value: string) => void;
  disabled?: boolean;
  className?: boolean;
  showCount?: boolean;
  placeholder?: string;
  rows?: number;
}

const Textarea: React.FC<TextareaProps> = ({ 
  value, 
  maxLength, 
  onChange,
  disabled = false,
  className,
  showCount = false,
  placeholder,
  rows = 5
}) => {
  const [text, setText] = useState(value);

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;

    if (disabled)
      return;

    if (maxLength && newValue.length > maxLength) {
      return;
    }

    setText(newValue);
    onChange(newValue);
  };

  const characterCount = text.length;
  const remainingCharacters = maxLength ? (maxLength - characterCount) : 0;
  
  const textareaClasses = 
    classNames("txt-area",
        {'txt-area-disabled': disabled}, 
        className);

  return (
    <div className='txt-area-wrapper'>
      <textarea
        className={textareaClasses}
        value={text}
        maxLength={maxLength}
        onChange={handleTextareaChange}
        spellCheck={false}
        placeholder={placeholder}
        disabled={disabled} rows={rows}
      />
      {showCount &&
        <span className={`txt-area-count ${remainingCharacters < 10 ? 'danger' : ''}`}>
          {characterCount}/{maxLength}
        </span>
      }
    </div>
  );
};

export default Textarea;