import React from 'react';
import Checkbox from '../../components/Checkbox/Checkbox';
import Navigation from '../navigation';

const CheckboxContainer: React.FC = () => {
    return (
      <>
        <Navigation/>
        <main className='main'>

          <section>
            <div className='title'>Small Checkboxes</div>
            <div className='section-body'>
              <Checkbox size='small'/>
              <Checkbox size='small' indeterminate={true}/>
              <Checkbox size='small' checked={true}/>
              <Checkbox size='small' disabled={true}/>
              <Checkbox size='small' disabled={true} indeterminate={true}/>
              <Checkbox size='small' disabled={true} checked={true}/><br/><br/>
              <Checkbox size='small' label='Checkbox'/>
              <Checkbox size='small' label='Checkbox' indeterminate={true}/>
              <Checkbox size='small' label='Checkbox' checked={true}/>
              <Checkbox size='small' label='Checkbox' disabled={true}/>
              <Checkbox size='small' label='Checkbox' disabled={true} indeterminate={true}/>
              <Checkbox size='small' label='Checkbox' disabled={true} checked={true}/>
            </div>
          </section>

          <section>
            <div className='title'>Default Checkboxes</div>
            <div className='section-body'>
              <Checkbox/>
              <Checkbox indeterminate={true}/>
              <Checkbox checked={true}/>
              <Checkbox disabled={true}/>
              <Checkbox disabled={true} indeterminate={true}/>
              <Checkbox disabled={true} checked={true}/><br/><br/>
              <Checkbox label='Checkbox'/>
              <Checkbox label='Checkbox' indeterminate={true}/>
              <Checkbox label='Checkbox' checked={true}/>
              <Checkbox label='Checkbox' disabled={true}/>
              <Checkbox label='Checkbox' disabled={true} indeterminate={true}/>
              <Checkbox label='Checkbox' disabled={true} checked={true}/>
            </div>
          </section>

          <section>
            <div className='title'>Large Checkboxes</div>
            <div className='section-body'>
              <Checkbox size='large'/>
              <Checkbox size='large' indeterminate={true}/>
              <Checkbox size='large' checked={true}/>
              <Checkbox size='large' disabled={true}/>
              <Checkbox size='large' disabled={true} indeterminate={true}/>
              <Checkbox size='large' disabled={true} checked={true}/><br/><br/>
              <Checkbox size='large' label='Checkbox'/>
              <Checkbox size='large' label='Checkbox' indeterminate={true}/>
              <Checkbox size='large' label='Checkbox' checked={true}/>
              <Checkbox size='large' label='Checkbox' disabled={true}/>
              <Checkbox size='large' label='Checkbox' disabled={true} indeterminate={true}/>
              <Checkbox size='large' label='Checkbox' disabled={true} checked={true}/>
            </div>
          </section>
        </main>
      </>
    );
  };
  
export default CheckboxContainer;