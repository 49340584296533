import React from 'react';
import Navigation from '../navigation';
import Avatar from '../../components/Avatar/Avatar';

const AvatarContainer: React.FC = () => {
    return (
      <>
        <Navigation/>
        <main className='main'>
          <section>
            <div className='title'>Circle Avatars</div>
            <div className='section-body'>
              <Avatar size='xlarge' altText='Savvas' imageUrl='https://i.pravatar.cc/150?img=60'/>
              <Avatar size='large' altText='Savvas' imageUrl='https://i.pravatar.cc/150?img=60'/>
              <Avatar altText='Savvas' imageUrl='https://i.pravatar.cc/150?img=60'/>
              <Avatar size='small' altText='Savvas' imageUrl='https://i.pravatar.cc/150?img=60'/>
            </div>
          </section>

          <section>
            <div className='title'>Square Avatars</div>
            <div className='section-body'>
              <Avatar size='xlarge' shape='square' altText='Savvas' imageUrl='https://i.pravatar.cc/150?img=60'/>
              <Avatar size='large' shape='square' altText='Savvas' imageUrl='https://i.pravatar.cc/150?img=60'/>
              <Avatar altText='Savvas' shape='square' imageUrl='https://i.pravatar.cc/150?img=60'/>
              <Avatar size='small' shape='square' altText='Savvas' imageUrl='https://i.pravatar.cc/150?img=60'/>
            </div>
          </section>
        </main>
      </>
    );
  };
  
export default AvatarContainer;