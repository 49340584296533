import React from 'react';
import Navigation from '../navigation';
import Alert from '../../components/Alert/Alert';

const AlertContainer: React.FC = () => {
    const handleShowMessage = () => {
      window.alert("Before close callback");
    }

    const handleShowMessageAfterClose = () => {
      window.alert("After close callback");
    }

    return (
      <>
        <Navigation/>
        <main className='main'>
          <section>
            <div className='title'>Alerts (Only title)</div>
            <div className='section-body'>
              <Alert title='Success' type='success'/>
              <Alert title='Info' type='info'/>
              <Alert title='Warning' type='warning'/>
              <Alert title='Error' type='error'/>
            </div>
          </section>
          <section>
            <div className='title'>Alerts (Only title) With Icon</div>
            <div className='section-body'>
              <Alert title='Success' showIcon = {true} type='success'/>
              <Alert title='Info' showIcon = {true} type='info'/>
              <Alert title='Warning' showIcon = {true} type='warning'/>
              <Alert title='Error' showIcon = {true} type='error'/>
            </div>
          </section>
          <section>
            <div className='title'>Alerts (Only title) With Icon - BeforeClose Callback</div>
            <div className='section-body'>
              <Alert title='Success'showIcon = {true} type='success' beforeClose={handleShowMessage} closeable={true}/>
            </div>
          </section>
          <section>
            <div className='title'>Alerts (Only title) With Icon - BeforeClose Callback and OnClose Callback</div>
            <div className='section-body'>
              <Alert title='Success'showIcon = {true} type='success' beforeClose={handleShowMessage}
              onClose={handleShowMessageAfterClose}
              closeable={true}/>
            </div>
          </section>
          <section>
            <div className='title'>Alerts (Title and Message)</div>
            <div className='section-body'>
              <Alert title='Success' message='Detailed description and advice about successful copywriting' type='success'/>
              <Alert title='Info' message='Detailed description and advice about successful copywriting' type='info'/>
              <Alert title='Warning' message='Detailed description and advice about successful copywriting' type='warning'/>
              <Alert title='Error' message='Detailed description and advice about successful copywriting' type='error'/>
            </div>
          </section>
          <section>
            <div className='title'>Alerts (Title and Message) With Icon</div>
            <div className='section-body'>
              <Alert title='Success' message='Detailed description and advice about successful copywriting' showIcon={true} type='success'/>
              <Alert title='Info' message='Detailed description and advice about successful copywriting' showIcon={true} type='info'/>
              <Alert title='Warning' message='Detailed description and advice about successful copywriting' showIcon={true} type='warning'/>
              <Alert title='Error' message='Detailed description and advice about successful copywriting' showIcon={true} type='error'/>
            </div>
          </section>
          <section>
            <div className='title'>Alerts (Title and Message) With Icon and Close Icon</div>
            <div className='section-body'>
              <Alert closeable={true} title='Success' message='Detailed description and advice about successful copywriting' showIcon={true} type='success'/>
              <Alert closeable={true} title='Info' message='Detailed description and advice about successful copywriting' showIcon={true} type='info'/>
              <Alert closeable={true} title='Warning' message='Detailed description and advice about successful copywriting' showIcon={true} type='warning'/>
              <Alert closeable={true} title='Error' message='Detailed description and advice about successful copywriting' showIcon={true} type='error'/>
            </div>
          </section>
        </main>
      </>
    );
  };
  
export default AlertContainer;