import React from 'react';
import './Breadcrumb.css';

type BreadcrumbItemNode = {
  label: string;
  url?: string;
};

interface BreadcrumbProps {
  items: BreadcrumbItemNode[];
  separator?: string;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ 
  items,
  separator 
}) => {
  return (
    <div className='breadcrumb'>
      <ol>
        {items.map((item, index) => (
          <li key={index} className = "breadcrumb-item">
            {index === items.length - 1
               ? (<BreadcrumbItem label={item.label}/>) 
               : (<BreadcrumbItem label={item.label} href={item.url} separator = {separator}/>)}
          </li>
        ))}
      </ol>
    </div>
  );
};

export interface BreadcrumbItemProps {
  label: string;
  href?: string;
  className?: string;
  separator?: string;
}

const BreadcrumbItem = (props: BreadcrumbItemProps) => {
  return (
    props.href !== undefined 
    ? (
      <>
        <a href={props.href} title={props.label}>{props.label}</a>
        <span className='breadcrumb-separator'>{props.separator}</span>
      </>
      )
    : (<span>{props.label}</span>)
  );
};

// interface BreadcrumbSeparatorProps {
//   children?: React.ReactNode;
// }

// const BreadcrumbSeparator = (props: BreadcrumbSeparatorProps) => {
//   return (
//     <li className={'breadcrumb-separator'} aria-hidden="true">
//       {props.children === '' ? props.children : props.children || '/'}
//     </li>
//   );
// }

export default Breadcrumb;