import React from 'react';
import Navigation from '../navigation';

const SwitchContainer: React.FC = () => {
    return (
      <div className='page-layout'>
        <Navigation/>
        <main className='main'>
          <div>Select</div>
        </main>
      </div>
    );
  };
  
export default SwitchContainer;