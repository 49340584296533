import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import './Radio.css';

interface RadioProps {
  label: string;
  value: string;
  name?: string;
  disabled?: boolean,
  selectedValue?: string;
  onChange?: (value: string) => void;
  size?: 'small' | 'default' | 'large';
}

const Radio: React.FC<RadioProps> = ({ 
  label, 
  value, 
  name,
  disabled = false, 
  selectedValue, 
  onChange,
  size }) => {
  
  const radioWrapperClasses = 
      classNames('radio-wrapper',
        {'radio-wrapper-checked': selectedValue === value},
        {'radio-wrapper-disabled': disabled},
        {'radio-small':size === 'small'},
        {'radio-large':size === 'large'});    
  
  const radioClasses = 
      classNames('radio',
        {'radio-checked': selectedValue === value},
        {'radio-disabled': disabled});    

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    if(disabled)
      return;

    if(onChange){
      onChange(event.target.value);
    }
  };

  return (
    <label className={radioWrapperClasses} key={value}>
      <span className={radioClasses}>
        <input name={name} type="radio" className="radio-input" value={value} checked={selectedValue === value} onChange={handleRadioChange}/>
        <span className = "radio-inner"></span>
      </span>
      {label && <span>{label}</span>}
    </label>
  );
};

export default Radio;