import React from 'react';
import './home-page.css';
import Navigation from './navigation';

const Home: React.FC = () => {
    return (
      <div className='page-layout'>
        <Navigation/>
        <main className='main'>
          <h3>Welcome to the Weebitlabs component design system</h3>
          <p>Here you can find a list of reusable UI components that are designed
            to maintain consistency and enhance efficiency in building user interfaces.
            Each component is designed with a clear purpose and functionality, following 
            a standardized structure and styling.
          </p>
          <p>One of the key advantages of a component design system in React is it's ability
            to promote collaboration and consistency among developers and designers. With a shared
            library of components and styles, team can work together more efficiently, as everyone
            follows the same design principles and uses the same set of components.
          </p>
        </main>
      </div>
    );
  };
  
export default Home;