import React, {useState, useEffect} from 'react';
import Navigation from '../navigation';
import Progress from '../../components/Progress/Progress';

const ProgressContainer: React.FC = () => {
    const[percentage, setPercentage] = useState(0);

    useEffect(() => {
      const timer = setInterval(() => {
        setPercentage((prevProgress) => {
          // Increase the progress by 1
          const newProgress = prevProgress + 1;
          
          // If the progress reaches 100, stop the timer
          if (newProgress === 100) {
            clearInterval(timer);
          }
  
          return newProgress;
        });
      }, 1000);

      // Clean up the timer on component unmount
      return () => {
        clearInterval(timer);
      };
    }, [])

    const handleProgress = (percentage: number) => {
      console.log(percentage);
    }

    const handleComplete = () => {
      console.log("Progress is completed");
    }

    return (
      <>
        <Navigation/>
        <main className='main'>
        <section>
            <div className='title'>Progress</div>
            <div className='section-body'>
              <Progress type='line' strokeLinecap='square' percent={50}/>
              <Progress type='line' strokeLinecap='butt' percent={20} status='exception'/>
              <Progress type='line' percent={30} status='active'/>
              <Progress type='line' percent={40} status='success'/>
            </div>
          </section>
          <section>
            <div className='title'>Progress</div>
            <div className='section-body'>
              <Progress type='line' percent={50} height={10}/>
              <Progress type='line' percent={20} height={9} status='exception'/>
              <Progress type='line' percent={30} height={8} status='active'/>
              <Progress type='line' percent={40} height={6} status='success'/>
            </div>
          </section>
          <section>
            <div className='title'>Progress</div>
            <div className='section-body'>
              <Progress type='line' percent={percentage} />
              <Progress type='line' status='exception' percent={percentage}/>
              <Progress type='line' status='success' percent={percentage}/>
            </div>
          </section>
          <section>
            <div className='title'>Progress (Circle)</div>
            <div className='section-body'>
              <Progress type='circle' percent={50} strokeLinecap='square'/>
              <Progress type='circle' percent={20} status='exception'/>
              <Progress type='circle' percent={30} status='active'/>
              <Progress type='circle' percent={40} status='success'/>
            </div>
          </section>
          <section>
            <div className='title'>Progress (Circle)</div>
            <div className='section-body'>
              <Progress type='circle' width={26} height={26} percent={percentage}/>
              <Progress type='circle' width={60} height={60} percent={percentage}/>
              <Progress type='circle' width={90} height={90} percent={percentage} onProgress={handleProgress} onComplete = {handleComplete} status='exception'/>
              <Progress type='circle' width={120} height={120} percent={percentage} status='success'/>
              <Progress type='circle' trailColor='lightgray' strokeWidth={15} strokeLinecap='butt' width={120} height={120} percent={percentage} status='success'/>
            </div>
          </section>
        </main>
      </>
    );
  };
  
export default ProgressContainer;