import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import './Button.css';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'primary' | 'default' | 'dashed';
    color?: string;
    size?: 'small' | 'medium' | 'large';
    disabled?: boolean;
    onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ children, variant, color, size, disabled, onClick, ...rest }) => {

  const buttonClasses = 
      classNames('btn',
        {'btn-primary': variant === 'primary'},
        {'btn-default': variant === 'default'},
        {'btn-dashed': variant === 'dashed'},
        {'btn-small':size === 'small'},
        {'btn-medium':size === 'medium'},
        {'btn-large':size === 'large'},
        {'btn-disabled': disabled},
        color);

  return (
    <button type='button' className={buttonClasses} {...rest}>
      <span>
        {children}
      </span>
    </button>
  );
};
  
export default Button;