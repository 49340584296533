import React, { useState, ChangeEvent } from 'react';
import classNames from 'classnames';
import './Checkbox.css';

interface CheckboxProps {
    label?: string;
    showLabel?: boolean;
    checked?: boolean;
    disabled?: boolean;
    indeterminate?: boolean,
    onChange?: (checked: boolean) => void;
    size?: 'small' | 'default' | 'large';
}

const Checkbox: React.FC<CheckboxProps> = ({ label, 
  showLabel = true, 
  checked: initialChecked = false, 
  disabled = false,
  indeterminate: initialIndeterminate = false,
  size = 'default',
  onChange 
}) => {

  const [checked, setChecked] = useState(initialChecked);
  const [indeterminate, setIndeterminate] = useState(initialIndeterminate);

  const checkBoxWrapperClasses = 
      classNames('checkbox-wrapper',
        {'checkbox-wrapper-checked': checked},
        {'checkbox-wrapper-disabled': disabled},
        {'checkbox-small':size === 'small'},
        {'checkbox-large':size === 'large'});    
  
  const checkBoxClasses = 
  classNames('checkbox',
    {'checkbox-checked': checked},
    {'checkbox-indeterminate': indeterminate},
    {'checkbox-disabled': disabled});   


  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if(disabled)
      return;
    
    const newChecked = event.target.checked;
    setChecked(newChecked);
    setIndeterminate(false);
    if(onChange){
      onChange(checked);
    }
  };

  return (
    <label className={checkBoxWrapperClasses}>
      <span className={checkBoxClasses}>
        <input type="checkbox" className="checkbox-input" checked={checked} onChange={handleChange}/>
        <span className = "checkbox-inner"></span>
      </span>
      {(showLabel && label) && <span>{label}</span>}
    </label>
  );
};
  
export default Checkbox;