import React, { useState } from 'react';
import Radio from '../../components/Radio/Radio';


interface RadioOption {
  label: string;
  value: string;
}

interface RadioGroupProps {
  options: RadioOption[];
  selectedValue?: string;
  name?: string
}

const RadioGroup: React.FC<RadioGroupProps> = ({ options, name }) => {

    const [selectedValue, setSelectedValue] = useState('');

    const handleRadioChange = (value: string) => {
        setSelectedValue(value);
    }

    return (
        <>
        {options.map((option) => (
            <Radio key={option.value}
            name = {name} 
            value={option.value} 
            selectedValue={selectedValue} 
            label={option.label} 
            onChange={handleRadioChange}
            />
        ))}
        </>
    );
};

export default RadioGroup;