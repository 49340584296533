import React, { forwardRef, Ref} from 'react';
import classNames from 'classnames';
import './Avatar.css';

interface AvatarProps {
  imageUrl: string;
  altText: string;
  size?: 'small' | 'default' | 'large' | 'xlarge';
  className?: string;
  shape?: 'circle' | 'square';
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
}

const Avatar: React.ForwardRefRenderFunction<HTMLSpanElement,AvatarProps> = (
  { 
    imageUrl, 
    altText, 
    size = 'default',
    className,
    shape = 'circle',
    onClick
  },
  ref: Ref<HTMLSpanElement>
) => {

  const avatarClasses = 
    classNames("avatar",
    {'avatar-circle': shape === 'circle'},
    {'avatar-square': shape === 'square'},
    {'avatar-small':size === 'small'},
    {'avatar-large':size === 'large'},
    {'avatar-xlarge':size === 'xlarge'},
    className);
 
  return (
      <span className={avatarClasses} ref = {ref}>
        <img src={imageUrl} alt={altText} title={altText} />
      </span>
  );
};

export default forwardRef(Avatar);