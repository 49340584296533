import React from 'react';
import { NavLink } from 'react-router-dom';
import './navigation.css';

const Navigation: React.FC = () => {
    return (
        <nav>
            <ul>
            <li className='active'>
                <span>WL</span>
                <NavLink to ="/">Weebitlabs</NavLink>
            </li>
            <li><NavLink to ="/alert">Alert</NavLink></li>
            <li><NavLink to ="/avatar">Avatar</NavLink></li>
            <li><NavLink to ="/badge">Badge</NavLink></li>
            <li><NavLink to ="/breadcrumb">Breadcrumb</NavLink></li>
            <li><NavLink to ="/button">Button</NavLink></li>
            <li><NavLink to ="/checkbox">Checkbox</NavLink></li>
            <li><NavLink to ="/dropdown">Dropdown</NavLink></li>
            <li><NavLink to ="/modal">Modal</NavLink></li>
            <li><NavLink to ="/passwordbox">Passwordbox</NavLink></li>
            <li><NavLink to ="/progress">Progress</NavLink></li>
            <li><NavLink to ="/radio">Radio</NavLink></li>
            <li><NavLink to ="/rating">Rating</NavLink></li>
            <li><NavLink to ="/select">Select</NavLink></li>
            <li><NavLink to ="/slider">Slider</NavLink></li>
            <li><NavLink to ="/switch">Switch</NavLink></li>
            <li><NavLink to ="/textarea">Textarea</NavLink></li>
            <li><NavLink to ="/textbox">Textbox</NavLink></li>
            <li><NavLink to ="/toast">Toast</NavLink></li>
            <li><NavLink to ="/tooltip">Tooltip</NavLink></li>
            </ul>
        </nav>
    );
  };
  
export default Navigation;