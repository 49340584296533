import React from 'react';
import classNames from 'classnames';
import './Badge.css';

interface BadgeProps {
  text: string;
  color?: string;
  className?: string;
  title?: string;
  size?: 'default' | 'small';
  status?: 'success' | 'processing' | 'error' | 'default' | 'warning'
}

const Badge: React.FC<BadgeProps> = ({ 
  text, 
  color = 'blue',
  className,
  title,
  size = 'default',
  status = 'default'
}) => {

  const badgeClasses = 
    classNames("badge",
    {'badge-small': size === 'small'},
    {'badge-status-default': status === 'default'},
    {'badge-status-success': status === 'success'},
    {'badge-status-processing': status === 'processing'},
    {'badge-status-error': status === 'error'},
    {'badge-status-warning': status === 'warning'},
    {'badge-multiple-characters': text && text.toString().length > 1},
    className);

  
  return (
    <span className={badgeClasses} title={title}>
      {text}
    </span>
  );
};

export default Badge;